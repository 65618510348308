import React, { ReactElement } from 'react';
import Head from 'next/head';

import { Image, Link } from 'components';
import { getLayout } from 'components/layouts/Header';
import { useResponsive } from 'hooks/useResponsive';
import { PAGE_ROUTES } from 'lib/page-routes';

import styles from './NotFound.module.scss';

const NotFound = (): ReactElement => {
  const screens = useResponsive();

  const redirectUrl = screens.sm ? PAGE_ROUTES.SOCIETIES : PAGE_ROUTES.HOME;

  return (
    <>
      <Head>
        <title>Page Not Found | Huzzle</title>
        <meta name="description" content="Huzzle" />
      </Head>
      <section className={styles.container} data-testid="NotFound">
        <div className={styles.content}>
          <div className={styles.image}>
            <Image src="not-found.png" source="aws" alt="Page not found" />
          </div>
          <p className={styles.title}>You have been 404’ed!</p>
          <p className={styles.description}>
            Whooops! The page you are looking for is gone! One can say it
            doesn’t exist.
          </p>
          <Link
            variant="contained"
            href={redirectUrl}
            className={styles.homeButton}
          >
            Go to Home
          </Link>
        </div>
      </section>
    </>
  );
};

// @ts-ignore
NotFound.getLayout = getLayout;

export default NotFound;
